<template>
    <div class="page3">
		<Coldiv direction="left">
            <div style="margin-top: 3.23vh">
				<Subtitle text="通行统计"></Subtitle>
				<div class="trafficStateBox"> 
					<percent-ring-2 class="trafficStateCard" :percent="table1.total?Math.round(table1.goIn*100/table1.total):0" ringColor="#ffd03b" itemName="车辆进入" :events="table1.goIn"></percent-ring-2>
					<percent-ring-2-rev class="trafficStateCard" :percent="table1.total?Math.round(table1.goOut*100/table1.total):0" ringColor="#63e7b7" itemName="车辆出去" :events="table1.goOut"></percent-ring-2-rev>
				</div>
                <Custom-table class="customTable" style="margin-top: 1.48vh" :columns="table1.columns" :data="table1.data"></Custom-table>
			</div>
            <div style="margin-top: 3.33vh">
                <Subtitle text="事件处理" :remark="'事件总数：'+table2.data.length"></Subtitle>
                <div class="eventBox">
                    <percent-ring class="percentRing" itemName="待处理" :percent="table2.total?Math.round(table2.pending*100/table2.total):0" ringColor="#ffd03b" :events="table2.pending"></percent-ring>
                    <percent-ring class="percentRing" itemName="处理中" :percent="table2.total?Math.round(table2.processing*100/table2.total):0" ringColor="#3dffff" :events="table2.processing"></percent-ring>
                    <percent-ring class="percentRing" itemName="已处理" :percent="table2.total?Math.round(table2.completed*100/table2.total):0" ringColor="#63e7b7" :events="table2.completed"></percent-ring>
                    <percent-ring class="percentRing" itemName="已审核" :percent="table2.total?Math.round(table2.verified*100/table2.total):0" ringColor="#d4deec" :events="table2.verified"></percent-ring>
                </div>
                <Custom-table class="customTable" style="margin-top: 1.11vh" :columns="table2.columns" :data="table2.data"></Custom-table>
            </div>
		</Coldiv>
        <div class="noticeBox">
            <info-card-4 class="environmentCard" :inputData="environmentData[0]"></info-card-4>
            <info-card-4 class="environmentCard" :inputData="environmentData[1]"></info-card-4>
            <info-card-4 class="environmentCard" :inputData="environmentData[2]"></info-card-4>
        </div>
		<Coldiv direction="right">
            <div style="margin-top: 3.23vh">
                <Subtitle text="社区物联"></Subtitle>
                <div class="iotBox">
                    <div class="iotInfoRow">
                        <iot-info class="iotInfo" :inputData="iotData[0]"></iot-info>
                        <iot-info class="iotInfo" :inputData="iotData[1]"></iot-info>
                    </div>
                    <div class="iotInfoRow">
                        <iot-info class="iotInfo" :inputData="iotData[2]"></iot-info>
                        <iot-info class="iotInfo" :inputData="iotData[3]"></iot-info>
                    </div>
                    <div class="iotInfoRow">
                        <iot-info class="iotInfo" :inputData="iotData[4]"></iot-info>
                        <iot-info class="iotInfo" :inputData="iotData[5]"></iot-info>
                    </div>
                    <div class="iotInfoRow">
                        <iot-info class="iotInfo" :inputData="iotData[6]"></iot-info>
                        <iot-info class="iotInfo" :inputData="iotData[7]"></iot-info>
                    </div>
                </div>
            </div>
            <div style="margin-top: 2.5vh">
                <Subtitle text="事件预警"></Subtitle>
                <div class="eventWarningBox">
                    <div class="cameraBox">
                        <div class="videoContainer">
                            <div class="videoBox" @click="()=>{watchBigVideo(0,'一单元12号楼')}">
                                <keep-alive>
									<hls-camera :videoSrc="videoSrc[0].mainUrl"></hls-camera>
								</keep-alive>
                            </div>
                        </div>
                        <div class="cameraInfoBox">
                            <div class="timeBox">{{time[1]}}/{{time[2]}}&ensp;{{time[3]}}:{{time[4]}}:{{time[5]}}</div>
                            <div class="positionBox">一单元12号楼</div>
                            <div class="markBox" style="border-color: #ff3b3b; color: #ff3b3b">车棚</div>
                        </div>
                    </div>
                    <div class="cameraBox">
                        <div class="videoContainer">
                            <div class="videoBox" @click="()=>{watchBigVideo(1,'一单元13号楼')}">
                                <keep-alive>
									<hls-camera :videoSrc="videoSrc[1].mainUrl"></hls-camera>
								</keep-alive>
                            </div>
                        </div>
                        <div class="cameraInfoBox">
                            <div class="timeBox">{{time[1]}}/{{time[2]}}&ensp;{{time[3]}}:{{time[4]}}:{{time[5]}}</div>
                            <div class="positionBox">一单元13号楼</div>
                            <div class="markBox" style="border-color: #ffd03b; color: #ffd03b">陌生</div>
                        </div>
                    </div>
                </div>
                <div class="eventWarningBox">
                    <div class="cameraBox">
                        <div class="videoContainer">
                            <div class="videoBox" @click="()=>{watchBigVideo(2,'一单元14号楼')}">
                                <keep-alive>
									<hls-camera :videoSrc="videoSrc[2].mainUrl"></hls-camera>
								</keep-alive>
                            </div>
                        </div>
                        <div class="cameraInfoBox">
                            <div class="timeBox">{{time[1]}}/{{time[2]}}&ensp;{{time[3]}}:{{time[4]}}:{{time[5]}}</div>
                            <div class="positionBox">一单元14号楼</div>
                            <div class="markBox" style="border-color: #ff3b3b; color: #ff3b3b">车棚</div>
                        </div>
                    </div>
                    <div class="cameraBox">
                        <div class="videoContainer">
                            <div class="videoBox" @click="()=>{watchBigVideo(3,'一单元15号楼')}">
                                <keep-alive>
									<hls-camera :videoSrc="videoSrc[3].mainUrl"></hls-camera>
								</keep-alive>
                            </div>
                        </div>
                        <div class="cameraInfoBox">
                            <div class="timeBox">{{time[1]}}/{{time[2]}}&ensp;{{time[3]}}:{{time[4]}}:{{time[5]}}</div>
                            <div class="positionBox">一单元15号楼</div>
                            <div class="markBox" style="border-color: #ff3b3b; color: #ff3b3b">车棚</div>
                        </div>
                    </div>
                </div>
            </div>
		</Coldiv>
	</div>
</template>

<script>
const Coldiv = () => import('./components/coldiv.vue');
const percentRing2 = () => import('./components/percentRing2.vue');
const percentRing2Rev = () => import('./components/percentRing2Rev.vue');
const Subtitle = () => import('./components/subtitle.vue');
const CustomTable = () => import('./components/table.vue');
const percentRing = () => import('./components/percentRing.vue');
const iotInfo = () => import('./components/iotInfo.vue');
const infoCard4 = () => import('./components/infoCard4.vue');
const hlsCamera = () => import("./components/hlsCamera.vue");

export default {
    name: 'page3',
    components: {
        Coldiv,
        percentRing2,
        percentRing2Rev,
        Subtitle,
        CustomTable,
        percentRing,
        iotInfo,
        infoCard4,
		hlsCamera
    },
    data() {
        return {
			pollingSIV: null,
			timer: null,
			time:[],
			tableShowLimit: 20,
			environmentData: [
                {
                    itemName: '水位监测',
                    iconSrc: './icon1.png',
                    value: 0,
                    unit: 'm',
                    subText: '水位上限 15m\xa0\xa0\xa0\xa0水位下限 5m',
                    tag: '正常',
                    tagColor: '#249a38'
                },
                {
                    itemName: '水质指数',
                    iconSrc: './icon2.png',
                    value: 0,
                    unit: 'μs/cm',
                    subText: '水质正常范围200-1000mg/L',
                    tag: '优',
                    tagColor: '#249a38'
                },
                {
                    itemName: '空气质量',
                    iconSrc: './icon3.png',
                    value: 0,
                    unit: '分',
                    subText: '优80-100\xa0\xa0\xa0\xa0良60-80\xa0\xa0\xa0\xa0差0-60',
                    tag: '优',
                    tagColor: '#249a38'
                }
			],
			table1:{
                columns: [
                    {
                        title: '车牌号',
                        key: 'carNumber',
                        width: 30.53
                    },{
                        title: '进出通道',
                        key: 'gateWay',
                        width: 28.95
                    },{
                        title: '通行',
                        key: 'direction',
                        width: 18.42
                    },{
                        title: '状态',
                        key: 'status',
                        width: 17.89
                    }
                ],
                data:[
                    {
                        carNumber: '加载中...',
                        gateWay: '加载中...',
                        direction: '加载中...',
                        status: '加载中...',
                        color: '#ffd03b'
                    }
                ],
                goIn: 0,
                goOut: 0,
                total: 0
			},
			table2:{
                columns: [
                    {
                        title: '事件',
                        key: 'event',
                        width: 31.32
                    },{
                        title: '负责人',
                        key: 'director',
                        width: 23.42
                    },{
                        title: '地点',
                        key: 'position',
                        width: 23.16
                    },{
                        title: '状态',
                        key: 'status',
                        width: 17.89
                    }
                ],
                data:[
                    {
                        event: '加载中...',
                        director: '加载中...',
                        position: '加载中...',
                        status: '加载中...',
                        color: '#ffd03b'
                    }
                ],
                processing: 0,
                completed: 0,
                pending: 0,
                aborted: 0,
                total: 0
			},
			iotData:[
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './气象站.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './水位传感器.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './水质传感器.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './电子围栏.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './手环.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './智慧停车.png'
                },
                {
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './广告机.png'
                },{
                    deviceName: '加载中...',
                    normal: 0,
                    warning: 0,
                    breakdown: 0,
					offline: 0,
                    iconUrl: './摄像头.png'
                }
			],
			videoSrc: [
				{
					mainUrl: '',
					minorUrl: ''
				},{
					mainUrl: '',
					minorUrl: ''
				},{
					mainUrl: '',
					minorUrl: ''
				},{
					mainUrl: '',
					minorUrl: ''
				}
			],
			videoIsPlaying: [0, 0, 0, 0],
			tableMaxShow: 30 //配置项：表格最多显示前几项
        }
    },
    mounted() {
		this.$eventBus.$emit('switchPage', 3);
        this.timer = setInterval(() => {
            let dateArr = this.$u.formatDate();
            this.time = dateArr;
        }, 1000);
        this.updatePageData();
		this.pollingSIV = setInterval(() => {
			this.updatePageData();
		}, this.$c.pollingInterval)
    },
    methods:{
        //页面初始化
		updatePageData(){
            this.getEnvironmentData();
			this.getCarTable();
			this.getEventTable();
			this.getIOTDevices();
			this.getVideo([5, 6, 7, 8]);
		},
		//获取物联网设备信息
		getIOTDevices(){
			let taskList = [
				this.newPromise(this.$api.getWeatherStation), //气象站
				this.newPromise(this.$api.getWaterSensor), // 水位传感器
				this.newPromise(this.$api.getWaterQualitySensor), //水质传感器
				this.newPromise(this.$api.getEFence), //电子围栏
				this.newPromise(this.$api.getBracelet), //手环
				this.newPromise(this.$api.getSmartParking), //智慧停车
				this.newPromise(this.$api.getADMachine), //广告机
				this.newPromise(this.$api.getCameraDevice), //摄像头
			];
			let pm = Promise.all(taskList);
			pm.then(res => {
				let ret = [];
				let devices = ['气象站','水位传感器','水质传感器','电子围栏','手环','智慧停车','广告机','摄像头'];
				for(let i = 0;i < res.length;i++){
					let list = res[i].data.rows;
					let item = {
						//deviceName: list[0].deviceType,
						deviceName: devices[i],
						normal: 0,
						breakdown: 0,
						warning: 0,
						offline: 0,
						iconUrl: './'+devices[i]+'.png',
                        inspectionData: 0 // 迎检数据，不为0时，则让iot-info组件优先显示该值
					}
                    //迎检场景下，所有设备都必须全部在线，所以直接写死
                    if(this.$c.inspection){
                        item.inspectionData = list.length;
                        item.normal = list.length;
                        // 摄像头的个数特别设置为75个
                        if(i == 7){
                            item.inspectionData = 75;
                            item.offline = 7;
                            item.normal = 68;
                        }
                    }else{
                        for(let j = 0;j < list.length;j++){
                            switch(list[j].status){
                                case '故障':
                                    item.breakdown++;
                                    break;
                                case '预警':
                                    item.warning++;
                                    break;
                                case '离线':
                                    item.offline++;
                                    break;
                                default:
                                    item.normal++;
                                    break;
                            }
                        }
                    }
					ret.push(item);
				}
				this.iotData = ret;
			}).catch(err => {
				console.log(err);
			})
		},
		//批量创建promise的函数
		newPromise(callback){
			return new Promise((resolve, reject) => {
				callback({
					pageSize: 99999
				}).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err);
				})
			})
		},
		//获取车辆进出信息
        getCarTable(){
            this.$api.getCarTable({
                pageSize: 9999999
            }).then(res => {
                let d = res.data.rows;
                let o = {
                    columns: [
                        {
                            title: '车牌号',
                            key: 'carNumber',
                            width: 30.53
                        },{
                            title: '进出通道',
                            key: 'gateWay',
                            width: 28.95
                        },{
                            title: '通行',
                            key: 'direction',
                            width: 18.42
                        },{
                            title: '状态',
                            key: 'status',
                            width: 17.89
                        }
                    ],
                    data:[],
                    goIn: 0,
                    goOut: 0,
                    total: res.data.total
                }
                for(let i = 0;i < this.tableMaxShow;i++){
					let deviceName = d[i].passageway;
					let dir;
					//新的判断逻辑是根据闸机名称来判断进还是出
					if(deviceName.indexOf('入口') != -1){
						dir = '进';
					}else{
						dir = '出';
					}
                    let obj = {
                        carNumber: d[i].licensePlateNumber,
                        gateWay: deviceName,
                        direction: dir,
                        status: d[i].carStatus
                    }
                    if(d[i].carStatus == '正常'){
                        obj.color = '#63e7b7';
                    }else{
                        obj.color = '#ff3b3b';
                    }
                    o.data.push(obj);
                }
				for(let j = 0;j < d.length;j++){
					let deviceName = d[j].passageway;
					if(deviceName.indexOf('入口') != -1){
						o.goIn++;
					}else{
						o.goOut++;
					}
				}
                // 特殊需求
                if(this.$c.inspection){
                    let {goIn, goOut} = o;
                    if(goIn > goOut + 60){
                        goOut = goIn - 61;
                    }else if(goOut > goIn + 60){
                        goIn = goOut - 61;
                    }
                    o.goIn = goIn;
                    o.goOut = goOut;
                    o.total = goIn + goOut;
                }
                this.table1 = o;
            }).catch(err => {
                console.log(err);
            })
        },
        //获取环境数据
		getEnvironmentData() {
			this.$api.getEnvironmentData().then(res => {
				let d = res.data.data;
				let aq = d.airQuality; //空气指数
				//let wq = d.waterQualityIndex;//水质指数
				if (aq < 60) {
					d.airRank = "差";
					d.airRankColor = "#ec7f00";
				} else if (aq >= 60 && aq < 80) {
					d.airRank = "良";
					d.airRankColor = "#0096d7";
				} else {
					d.airRank = "优";
					d.airRankColor = "#249a38";
				}
                let h = this.$u.formatDate()[3];
                h = parseInt(97*(Math.cos(7.3*h*h))%21);
                d.waterQualityIndex = 200 + h;
				let r = [
                    {
                        itemName: '水位监测',
                        iconSrc: './icon1.png',
                        value: 10,
                        unit: 'm',
                        subText: '水位上限 15m\xa0\xa0\xa0\xa0水位下限 5m',
                        tag: '正常',
                        tagColor: '#249a38'
                    },
                    {
                        itemName: '水质指数',
                        iconSrc: './icon2.png',
                        value: d.waterQualityIndex,
                        unit: 'μs/cm',
                        subText: '水质正常范围200-1000mg/L',
                        tag: '优',
                        tagColor: '#249a38'
                    },
                    {
                        itemName: '空气质量',
                        iconSrc: './icon3.png',
                        value: aq,
                        unit: '分',
                        subText: '优80-100\xa0\xa0\xa0\xa0良60-80\xa0\xa0\xa0\xa0差0-60',
                        tag: d.airRank,
                        tagColor: d.airRankColor
                    }
                ];
                this.environmentData = r;
			}).catch((err) => {
				console.log(err);
            });
        },
		//获取监控视频地址列表
		getVideo(arr) {
			this.$api.getCameraUrl({
				pageSize: 9
			}).then(res => {
				let d = res.data.rows;
				let list = [];
				for(let j = 0;j < 4;j++){
					list[j] = this.videoSrc[j];
				}
				let playing = this.videoIsPlaying;
				//获取列表第6至9项
				for(let a = 0;a < arr.length;a++){
					let i = arr[a];
					let item;
					if(this.$c.useExternalNetwork){
						//播放外网资源
						item = {
							mainUrl: this.$c.externalLiveBaseUrl + d[i].mainUrl,
							minorUrl: this.$c.externalLiveBaseUrl + d[i].minorUrl
						}
					}else{
						//播放内网资源
						item = {
							mainUrl: this.$c.intranetLiveBaseUrl + d[i].mainUrl,
							minorUrl: this.$c.intranetLiveBaseUrl + d[i].minorUrl
						}
					}
					playing[i-5] = 1;
					list[i-5] = item;
				}
				this.videoSrc = list;
				this.videoIsPlaying = playing;
				if(this.$c.useExternalNetwork){
					let sto = setTimeout(() => {
						//外网1分钟后停止直播，节约流量，刷新后再重新直播
						let list2 = [];
						for(let j = 0;j < 4;j++){
							list2[j] = this.videoSrc[j];
						}
						for(let a = 0;a < arr.length;a++){
							let i = arr[a] - 5;
							let item = {
								mainUrl: '',
								minorUrl: ''
							}
							list2[i] = item;
							this.videoIsPlaying[i] = 0;
						}
						this.videoSrc = list2;
						clearTimeout(sto);
					}, this.$c.videoTimeLimit);
				}
			}).catch(err => {
				console.log(err);
			})
		},
        //观看放大化的监控视频画面
        watchBigVideo(i, n){
			if(this.videoIsPlaying[i]){
				let url = this.videoSrc[i].mainUrl;
				let videoName = n;
				this.$eventBus.$emit('showBigVideo', {url, videoName});
			}else{
				this.getVideo([i+5]);
			}
        },
		//获取社区事件列表
        getEventTable(){
            this.$api.getEventTable({
                pageSize: 9999999
            }).then(res => {
                let d = res.data.rows;
                let o = {
                    columns: [
                        {
                            title: '事件',
                            key: 'event',
                            width: 31.32
                        },{
                            title: '负责人',
                            key: 'director',
                            width: 23.42
                        },{
                            title: '地点',
                            key: 'position',
                            width: 23.16
                        },{
                            title: '状态',
                            key: 'status',
                            width: 17.89
                        }
                    ],
                    data:[],
                    processing: 0,
                    verified: 0,
                    completed: 0,
                    pending: 0,
                    aborted: 0,
                    total: res.data.total
                }
                /* let lmt;
                if(this.tableShowLimit && d.length > this.tableShowLimit){
                    lmt = this.tableShowLimit;
                }else{
                    lmt = d.length;
                } */
                for(let i = 0;i < d.length;i++){
                    let obj = {
                        event: d[i].eventDescription,
                        director: d[i].reflectingPerson,
                        position: d[i].address,
                        status: d[i].status
                    }
                    if(d[i].status == '待处理'){
                        obj.color = '#ff9118';
                        o.pending++;
                    }else if(d[i].status == '已处理'){
                        obj.color = '#63e7b7';
                        o.completed++;
                    }else if(d[i].status == '处理中'){
                        obj.color = '#3dffff';
                        o.processing++;
                    }else if(d[i].status == '已审核'){
                        obj.color = '#63e7b7';
                        o.verified++;
                    }else{
                        if(this.$c.inspection){
                            obj.status = '已审核';
                            obj.color = '#63e7b7';
                            o.verified++;
                        }else{
                            obj.color = '#ff3b3b';
                            o.aborted++;
                        }
                    }
                    o.data.push(obj);
                }
                this.table2 = o;
            }).catch(err => {
                console.log(err);
            })
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
		clearInterval(this.pollingSIV);
    }
}
</script>

<style lang="less" scoped>
@import "~@/assets/less/index.less";
.page3 {
	height: 100%;
    width: 100%;
    background: none;
    overflow: hidden;
	display: flex;
	justify-content: space-between;
	transform: translateY(-1.11vh);
	z-index: 10;

    .trafficStateBox {
		height: 7.41vh;
		width: 88.1%;
		margin-top: 1.85vh;
		margin-left: 5.95%;
		display: flex;
		justify-content: space-between;
		
		.trafficStateCard {
			width: 47.37%;
		}
	}
    .customTable {
        width: 90.48%;
        margin-left: 4.76%;

    }
    .eventBox {
        width: 92.85%;
        height: 9.44vh;
        margin-left: 3.57%;
        margin-top: 1.85vh;
        display: flex;
        justify-content: space-between;

        .percentRing {
            width: 23.08%;
        }
    }
    .iotBox {
        width: 90.48%;
        margin-left: 4.76%;

        .iotInfoRow {
            width: 100%;
            height: 9.444vh;
            display: flex;
            justify-content: space-between;
            margin-top: 1.67vh;

            .iotInfo {
                width: 47.37%;
            }
        }
    }
    .eventWarningBox {
        width: 90.48%;
        margin-left: 4.76%;
        height: 14.35vh;
        margin-top: 1.85vh;
        display: flex;
        justify-content: space-between;

        .cameraBox {
            height: 100%;
            width: 47.37%;
            border: 1px solid #f3b805;

            .videoContainer {
                width: 100%;
                height: 56.774%;
                margin-top: 7.74%;
                display: flex;
                justify-content: center;

                .videoBox {
                    width: 86.67%;
                    height: 100%;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
            .cameraInfoBox {
                margin-top: 2.58%;
                width: 86.67%;
                height: 25.16%;
                margin-left: 6.67%;
                position: relative;

                .timeBox {
                    position: absolute;
                    height: 1.944vh;
                    font-size: 1.3vh;
                    line-height: 1.944vh;
                    font-family: 'robotoMedium', sans-serif;
                    color: #fff;
                    font-weight: 500;
                }
                .positionBox {
                    height: 1.67vh;
                    position: absolute;
                    bottom: 0;
                    font-family: 'pingfangM', sans-serif;
                    font-size: 1.11vh;
                    line-height: 1.67vh;
                    font-weight: 500;
                    color: rgba(255,255,255,0.8);
                }
                .markBox {
                    position: absolute;
                    width: 28.5%;
                    height: 2.41vh;
                    right: 0;
                    bottom: 10.26%;
                    border-width: 1px;
                    border-style: solid;
                    border-radius: 0.74vh;
                    font-size: 1.3vh;
                    line-height: 2.41vh;
                    font-family: 'pingfangM', sans-serif;
                    font-weight: 500;
                    text-align: center;
                    
                }

            }
        }

    }
    .noticeBox {
        margin-top: 3.23vh;
        width: 29.375%;
        height: 8.98vh;
        display: flex;
        justify-content: space-between;

        .environmentCard {
            width: 31.91%;
        }
    }
}

</style>
